@import url(https://fonts.googleapis.com/css?family=VT323);
.style_alert__2FRON {
    position: relative;
    width: 510px; 
    height: 70px;

    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: width 0.5s;

   
  }

  @media only screen and (max-width: 600px) {
    .style_alert__2FRON {
      width: 310px; 
      height: 70px;
    }
  }
  .style_error__29ouv {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }
  
  .style_success__3t56s {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
  }
  
  .style_warning__176hW {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
  }
  .style_primary__33sVx {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;
  }
  
  .style_secondary__2NiN- {
    color: #41464b;
    background-color: #e2e3e5;
    border-color: #d3d6d8;
  }
  
  .style_info__2As_6 {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb;
  }
  /* The close button */
  .style_closebtn__29wL6 {
    margin-left: 15px;
    margin-right: 5px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  /* When moving the mouse over the close button */
  .style_closebtn__29wL6:hover {
    color: black;
  }
  .style_hide__1_25q {
    display: none;
  }

 








html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: black;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}



@font-face {
  font-family: "VT323";
  src: local("VT323"), url(https://fonts.googleapis.com/css?family=VT323) ;
}

body {
  margin: 0;
  font-family: "VT323", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "VT323",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

